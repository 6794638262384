<template>
  <div>
    <div class="my-5 px-8">
      <PageHeader
        create-btn-title="Создать сервисный баннер"
        page-title="Сервисные баннеры"
        :searchable="false"
        @click:create="edit"
      />
      <VDialog
        v-model="notificationDialog"
        content-class="br-12"
        max-width="1248"
      >
        <Notification
          :id="selectedNotificationId"
          :key="key"
          :cities="cities"
          :hubs="hubs"
          :targets="targets"
          @close="notificationDialog = false"
          @update="getNotifications"
        />
      </VDialog>
      <VDivider />
      <div class="d-flex my-4">
        <Dropdown
          :items="targets"
          :selected="selectedTargets"
          title="Все платформы"
          @selected="selectedTargets = $event"
        />
        <Dropdown
          :items="cities"
          :selected="selectedCities"
          title="Все регионы"
          @selected="selectedCities = $event"
        />
        <Dropdown
          :items="hubs"
          :selected="selectedHubs"
          title="Все хабы"
          @selected="selectedHubs = $event"
        />
        <Dropdown
          :items="states"
          :selected="selectedStates"
          title="Все статусы"
          @selected="selectedStates = $event"
        />
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="notificationsHeaders(cities.length || 0, hubs.length || 0)"
      :items="notifications"
      :page="currentPage"
      @deactivate="deactivateNotification($event.id)"
      @edit="edit($event.id)"
      @update-page="currentPage = $event"
      @update-sort="selectedSort = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  getTargetList,
  fetchHubs,
  fetchNotifications,
  getNotificationById,
  updateNotification,
} from '@/api/api';
import { notificationsHeaders } from '@/conditions/tablesHeaders';
import { itemsPerPage } from '@/helpers/table';
import PageHeader from '@/components/reusable/PageHeader.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import Notification from '@/components/Notification.vue';

const defaultStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'inactive' }];

export default {
  components: {
    Notification,
    UniversalTable,
    Dropdown,
    PageHeader,
  },
  data() {
    return {
      notificationsHeaders,
      notificationDialog: false,
      notifications: [],
      currentPage: 1,
      selectedNotificationId: null,
      selectedCities: [],
      hubs: [],
      selectedHubs: [],
      targets: [],
      selectedTargets: [],
      states: defaultStates,
      selectedStates: defaultStates,
      key: 0,
    };
  },
  mounted() {
    this.getCities().then(() => {
      this.selectedCities = this.cities;
    });
    fetchHubs({ limit: 100 }).then((response) => {
      this.hubs = response.data;
      this.selectedHubs = response.data;
    });
    getTargetList().then((response) => {
      this.targets = response;
      this.selectedTargets = response;
    });
    this.getNotifications();
  },
  computed: {
    ...mapGetters(['cities']),
    selectedTargetsCitiesHubs() {
      const targets = this.stringifyParams(this.targets.length, this.selectedTargets, 'targets');
      const cities = this.stringifyParams(this.cities.length, this.selectedCities, 'cities');
      const hubs = this.stringifyParams(this.hubs.length, this.selectedHubs, 'hubs');
      return targets
        + (targets.length ? cities.replace('?', '&') : cities)
        + (targets.length || cities.length ? hubs.replace('?', '&') : hubs);
    },
  },
  methods: {
    ...mapActions(['getCities']),
    stringifyParams(total, selectedItems, title) {
      if (selectedItems.length === total || !selectedItems.length) {
        return '';
      }
      return `?${selectedItems.map((item) => `${title}=${item.id || item.pk}`).join('&')}`;
    },
    getNotifications() {
      const params = {
        page: this.currentPage,
        page_size: itemsPerPage,
      };
      if (this.selectedStates.length === 1) {
        params.is_active = this.selectedStates[0].value === 'active';
      }
      fetchNotifications(params, this.selectedTargetsCitiesHubs).then((response) => {
        this.notifications = response.result || [];
        this.$refs.table.totalPage = response.total_pages;
        this.$refs.table.totalCount = response.total_count;
      });
    },
    edit(id = null) {
      this.selectedNotificationId = id;
      this.key += 1;
      this.notificationDialog = true;
    },
    deactivateNotification(id) {
      getNotificationById(id).then((notification) => {
        // eslint-disable-next-line no-param-reassign
        notification.state = 'inactive';
        updateNotification(notification).then(() => {
          this.getNotifications();
        });
      });
    },
    needsToBeUpdated(oldCount, newCount, total) {
      return !((oldCount === total && newCount === 0) || (oldCount === 0 && newCount === total));
    },
  },
  watch: {
    currentPage() {
      this.getNotifications();
    },
    selectedTargetsCitiesHubs() {
      this.getNotifications();
    },
    selectedStates(oldVal, newVal) {
      if (this.needsToBeUpdated(oldVal.length, newVal.length, 2)) {
        this.getNotifications();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
