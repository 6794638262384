<template>
  <VCard>
    <div class="top-sticky white">
      <div class="d-flex justify-space-between align-center pa-3">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn icon @click="closeNotificationDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VDivider />
    </div>
    <VForm ref="form" lazy-validation>
      <VRow class="mx-0">
        <VCol class="pl-6 pr-10">
          <VRow>
            <VCol>
              <WideTextField
                v-model="notification.ruTitle"
                label="Название баннера Рус"
              />
              <StoryTextField
                v-model="notification.ruDescription"
                class="mt-5"
                label="Введите текст на русском"
              />
            </VCol>
            <VCol>
              <WideTextField
                v-model="notification.kzTitle"
                label="Название баннера Каз"
              />
              <StoryTextField
                v-model="notification.kzDescription"
                class="mt-5"
                label="Введите текст на казахском"
              />
            </VCol>
          </VRow>
          <div class="my-5">
            <div class="section-title">
              Загрузите изображения
            </div>
            <span class="section-caption">
              Изображения будут на главной странице приложения.
              Формат: JPEG, PNG, GIF. Вес не более 200 кб.
            </span>
          </div>
          <div>
            <div class="d-flex align-center">
              <ImageUploadBox
                v-model="notification.backgroundImage"
                class="mr-5"
                :deletable="!!notification.backgroundImage"
                height="140"
                required
                text="Загрузить фоновое изображение"
                width="204"
                @delete="notification.backgroundImage = ''"
              />
              <StoryTextField
                v-model="notification.backgroundImage"
                label="Укажите ссылку фонового изображения"
              />
            </div>
            <div class="d-flex align-center">
              <ImageUploadBox
                v-model="notification.icon"
                class="mr-5 mt-2"
                :deletable="!!notification.icon"
                height="140"
                required
                text="Загрузить иконку"
                width="204"
                @delete="notification.icon = ''"
              />
              <StoryTextField
                v-model="notification.icon"
                label="Укажите ссылку иконки"
              />
            </div>
            <div class="d-flex align-center max-w-50 mb-4">
              <VMenu
                v-model="colorPickerMenu"
                :close-on-content-click="false"
                content-class="br-12"
                nudge-right="200"
                nudge-top="350"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <VBtn
                    v-bind="attrs"
                    class="elevation-0 mb-3 mr-2 grey-br"
                    :color="notification.backgroundColor"
                    fab
                    height="20"
                    width="20"
                    v-on="on"
                  />
                </template>
                <ColorPicker
                  v-if="colorPickerMenu"
                  v-model="notification.backgroundColor"
                  :saved-colors="savedColors"
                  @close="colorPickerMenu = false"
                  @deleteColor="deleteColor"
                  @saveColor="saveColor"
                />
              </VMenu>
              <StoryTextField
                v-model="notification.backgroundColor"
                label="Цвет фона под текстом"
                placeholder="#FFFFFF"
                :rules="[(v) => v.startsWith('#') && v.length === 7]"
              />
            </div>
          </div>
        </VCol>
        <VDivider vertical />
        <VCol class="px-4" cols="3">
          <div class="font-weight-bold mb-2">
            Статус
          </div>
          <VMenu content-class="elevation-1" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                block
                class="elevation-0 d-flex justify-space-between pl-1 pr-2"
                :color="stateParams[notification.state].color"
                :style="{'color': stateParams[notification.state].textColor}"
                v-on="on"
              >
                <div class="subtitle-1">
                  <VIcon>mdi-circle-small</VIcon>
                  {{ stateParams[notification.state].title }}
                </div>
                <VIcon size="18">
                  mdi-chevron-down
                </VIcon>
              </VBtn>
            </template>
            <VList>
              <VListItemGroup v-model="notification.state">
                <VListItem
                  v-for="(state, index) in states"
                  v-show="state !== notification.state"
                  :key="index"
                  dense
                  :value="state"
                >
                  <VListItemTitle class="subtitle-1 pl-6" v-text="stateParams[state].title" />
                </VListItem>
              </VListItemGroup>
            </VList>
          </VMenu>
          <VDivider class="mt-3 mb-4" />
          <div id="date" class="font-weight-bold mb-2">
            Дата кампании
          </div>
          <DateTimePicker
            :date-end="notification.dtEnded"
            :date-start="notification.dtStarted"
            range
            @date-end="notification.dtEnded = $event"
            @date-start="notification.dtStarted = $event"
          />
          <VDivider class="mt-3 mb-4" />
          <ParamSelector
            v-model="notification.targets"
            attach="targets"
            :items="targets"
            title="Платформы"
          />
          <VDivider class="my-4" />
          <ParamSelector
            v-model="notification.cities"
            attach="cities"
            height="277"
            :items="cities"
            title="Регионы"
          />
          <VDivider class="my-4" />
          <ParamSelector
            v-model="notification.hubs"
            attach="hubs"
            height="245"
            :items="hubs"
            title="Хабы"
          />
          <VDivider class="my-4" />
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky">
      <VDivider />
      <div
        class="d-flex align-center grey lighten-5 py-3 pl-6 pr-4"
        :class="id ? 'justify-space-between' : 'justify-end'"
      >
        <div v-if="id" class="text--secondary">
          <span v-if="notification.dtUpdated.length">
            Изменено: {{ formatDate(notification.dtUpdated) }}
          </span>
          <span v-else> Создано: {{ formatDate(notification.dtCreated) }} </span>
        </div>
        <div class="subtitle-2">
          <VBtn
            v-show="!id"
            class="subtitle-2 mr-2 br-8 font-weight-bold"
            color="#E43539"
            height="40"
            text
            @click="save(false)"
          >
            Сохранить
          </VBtn>
          <VBtn
            class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
            :class="{'px-6': !!id}"
            color="#E43539"
            height="40"
            @click="save(!id)"
          >
            {{ id ? 'Обновить' : 'Создать и активировать' }}
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import ColorPicker from '@/components/reusable/ColorPicker.vue';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import WideTextField from '@/components/reusable/WideTextField.vue';
import StoryTextField from '@/components/reusable/StoryTextField.vue';
import ImageUploadBox from '@/components/reusable/ImageUploadBox.vue';
import { storyStates } from '@/conditions/states';
import DateTimePicker from '@/components/reusable/DateTimePicker.vue';
import ParamSelector from '@/components/reusable/ParamSelector.vue';
import { getNotificationById, createNotification, updateNotification } from '@/api/api';
import { formatDateWithTime } from '@/helpers/date';

export default {
  components: {
    ColorPicker,
    ParamSelector,
    DateTimePicker,
    ImageUploadBox,
    StoryTextField,
    WideTextField,
    Breadcrumbs,
  },
  props: {
    id: {
      type: [Number, null],
      default: null,
    },
    targets: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
    hubs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update', 'close'],
  data() {
    return {
      notification: {},
      stateParams: storyStates,
      states: ['active', 'inactive'],
      formatDate: formatDateWithTime,
      colorPickerMenu: false,
      savedColors: [],
    };
  },
  mounted() {
    this.setupNotification();
  },
  computed: {
    breadcrumbs() {
      return [
        { text: 'Сервисные баннеры', disabled: false, href: '/notifications' },
        {
          text: this.notification && this.notification.id
            ? `Cервисный баннер ID-${this.notification.id}`
            : 'Создать новый сервисный баннер',
          disabled: true,
          href: '',
        },
      ];
    },
  },
  methods: {
    setupNotification() {
      if (this.id) {
        getNotificationById(this.id).then((response) => {
          this.notification = response;
        });
      } else {
        this.notification = {
          kzTitle: '',
          ruTitle: '',
          kzDescription: '',
          ruDescription: '',
          backgroundImage: '',
          backgroundColor: '#FFFFFF',
          icon: '',
          arrangement: 0,
          dtStarted: '',
          dtEnded: '',
          targets: [],
          cities: [],
          hubs: [],
          state: 'inactive',
        };
      }
    },
    closeNotificationDialog() {
      this.$emit('close');
    },
    save(activate = false) {
      if (this.$refs.form.validate()) {
        if (this.id) {
          updateNotification(this.notification).then(() => this.$emit('update'));
        } else {
          if (activate) {
            this.notification.state = 'active';
          }
          createNotification(this.notification).then(() => this.$emit('update'));
        }
        this.closeNotificationDialog();
      }
    },
    saveColor(color) {
      this.savedColors.push(color);
    },
    deleteColor(index) {
      this.savedColors.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
